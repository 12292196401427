import React, { Fragment } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import { AboutHero } from "../Hero"
import { LayoutContainer } from "../UI"
import { useAboutPageData } from "../../system/DatoCms/AboutPageData"

const AboutToucan = () => {
  const {
    title,
    description,
    descriptionTwo,
    foregroundImage,
    paragraph,
    paragraphImage,
    bannerImage,
  } = useAboutPageData()
  return (
    <Fragment>
      <AboutHero
        title={title}
        heroPhones={foregroundImage}
        description={description}
        descriptionTwo={descriptionTwo}
      />
      <section className="mt-[-90px] mb-[128px] font-spartan md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
        <LayoutContainer className="items-center justify-center md:flex">
          <div className="mb-14 max-w-[500px] px-2 md:mr-10 md:mb-0 md:px-0">
            <div className="mb-6 md:hidden">
              <StructuredText
                data={descriptionTwo}
                customRules={[
                  renderRule(isParagraph, ({ children, key }) => {
                    if (children[0].length === 0) return <br />
                    return (
                      <p
                        key={key}
                        className="text-base font-bold leading-[25.92px] text-gray-1"
                      >
                        {children}
                      </p>
                    )
                  }),
                ]}
              />
            </div>
            <StructuredText
              data={paragraph}
              customRules={[
                renderRule(isParagraph, ({ children, key }) => {
                  if (children[0].length === 0) return <br />
                  return (
                    <p
                      key={key}
                      className="text-base leading-[25.92px] text-gray-1"
                    >
                      {children}
                    </p>
                  )
                }),
              ]}
            />
          </div>
          <GatsbyImage
            image={paragraphImage.gatsbyImageData}
            alt={paragraphImage.alt}
            className="max-w-[502px] rounded-[47px] shadow-step-card"
          />
        </LayoutContainer>
      </section>
      <section className="relative mx-auto mt-[-50px] mb-[128px] max-w-screen-2xl md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
        <GatsbyImage
          image={bannerImage.gatsbyImageData}
          alt={bannerImage.alt}
          className="w-full"
        />
        <span
          className="absolute inset-0"
          style={{ boxShadow: "inset 0px 0px 33px rgba(0, 0, 0, 0.2)" }}
        />
      </section>
    </Fragment>
  )
}

export default AboutToucan
