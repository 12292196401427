import { useStaticQuery, graphql } from "gatsby"

const useAboutPageData = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsAboutPage {
        nodes {
          title
          description {
            value
          }
          descriptionTwo {
            value
          }
          backgroundImage {
            alt
            gatsbyImageData
          }
          foregroundImage {
            alt
            gatsbyImageData
          }
          paragraph {
            value
          }
          paragraphImage {
            alt
            gatsbyImageData
          }
          bannerImage {
            alt
            gatsbyImageData
          }
        }
      }
    }
  `)
  return data.allDatoCmsAboutPage.nodes[0]
}

export default useAboutPageData
