import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import { LayoutContainer } from "../UI"

const AboutHero = ({ title, heroPhones, description, descriptionTwo }) => {
  return (
    <section className="relative mt-[-80px] mb-[128px] rounded-b-[50px] bg-hero-mobile pt-[144px] font-spartan md:mt-[-96px] md:mb-[140px] md:rounded-b-[96px] lg:mb-[151px]">
      <LayoutContainer className="relative z-10 items-center justify-between lg:flex">
        <div className="mx-auto max-w-[600px] xl:w-[700px]">
          <h2
            className={`custom-text-shadow mb-[38px] text-center text-[48px] font-bold leading-[52px] text-white md:text-[64px] md:leading-[78px] lg:text-left`}
          >
            {title}
          </h2>
          <div className="pb-[60px] text-center text-[16px] font-bold leading-[26px] tracking-[1px] text-white md:mb-8 md:pb-0 lg:text-left">
            <StructuredText
              data={description}
              customRules={[
                renderRule(isParagraph, ({ children, key }) => {
                  if (children[0].length === 0) return <br />
                  return (
                    <p
                      key={key}
                      className="text-xl leading-[25.92px] md:font-bold"
                    >
                      {children}
                    </p>
                  )
                }),
              ]}
            />
            <div className="hidden md:block">
              <StructuredText
                data={descriptionTwo}
                customRules={[
                  renderRule(isParagraph, ({ children, key }) => {
                    if (children[0].length === 0) return <br />
                    return (
                      <p
                        key={key}
                        className="text-xl leading-[25.92px] md:font-bold"
                      >
                        {children}
                      </p>
                    )
                  }),
                ]}
              />
            </div>
          </div>
        </div>
        <div className="hidden max-w-[535px] md:self-end lg:block">
          <GatsbyImage
            image={heroPhones.gatsbyImageData}
            alt="Hero Phones"
            className="mb-0 w-full"
          />
        </div>
      </LayoutContainer>
    </section>
  )
}

export default AboutHero
