import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { Main } from "../components/UI"
import AboutToucan from "../components/AboutToucan"

const IndexPage = ({ data }) => {
  const canonicalUrl = `https://thetoucan.app/about/`
  return (
    <Layout isHomePage>
      <Seo
        title="About Toucan - Show the world what you stand for."
        noIndex={data.allDatoCmsAboutPage.nodes[0].noIndex}
        canonicalUrl={canonicalUrl}
      />
      <Main className="">
        <AboutToucan />
      </Main>
    </Layout>
  )
}

export const query = graphql`
  {
    allDatoCmsAboutPage {
      nodes {
        noIndex
      }
    }
  }
`

export default IndexPage
