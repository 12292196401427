import React, {useState} from "react"
import {GatsbyImage} from "gatsby-plugin-image"
import {renderRule, StructuredText} from "react-datocms"
import {isParagraph} from "datocms-structured-text-utils"
// import {useRecoilState} from "recoil"

import {useMonthlyDonationData} from "../../system/DatoCms/HomepageData"
import {VideoThumbnail, LayoutContainer} from "../UI"
import YoutubeModal from "../YoutubeModal"
// import {signupModalState} from "../../state"

const Hero = ({
                  title,
                  iosDownloadLink,
                  androidDownloadLink,
                  heroPhones,
                  backgroundImage,
                  description,
                  showVideoButton,
              }) => {
    // const [menu, setMenu] = useRecoilState(signupModalState)
    const {
        data: {youtubeVideoId},
    } = useMonthlyDonationData()
    const [openVideoModal, setOpenVideoModal] = useState(false)

    const playVideo = () => {
        setOpenVideoModal(true)
    }

    return (
        // <section className="relative mt-[-80px] md:mt-[-96px] mb-[128px] md:mb-[140px] lg:mb-[151px] font-spartan bg-hero-mobile pt-[144px] rounded-b-[50px] md:rounded-b-[96px]"> // Temporary Used for Displaying Banner
        <section
            className="relative mt-[-103px] mb-[-40px] rounded-b-[50px] bg-hero-mobile pt-[144px] font-spartan md:mt-[-96px] md:mb-[140px] md:rounded-b-[96px] lg:mb-[151px]">
            <div className="absolute inset-0 hidden md:block">
                <GatsbyImage
                    image={backgroundImage.gatsbyImageData}
                    alt="Toucan Charity Donation - Hero Image"
                    className="h-full w-full rounded-b-[50px] md:rounded-b-[96px]"
                    loading="eager"
                />
            </div>
            {/* --------------------------- */}
            <LayoutContainer className="relative z-10 items-center justify-between lg:flex">
                <div
                    className={
                        heroPhones?.gatsbyImageData
                            ? "mx-auto max-w-[600px] xl:w-[700px]"
                            : "max-w-[600px] xl:w-[700px]"
                    }
                >
                    <h2
                        className={`custom-text-shadow mb-[32px] text-center text-[48px] font-bold leading-[52px] text-white md:text-[64px] md:leading-[78px] lg:text-left`}
                    >
                        {title}
                    </h2>
                    <div
                        className="pb-[72px] text-center text-[16px] font-bold leading-[26px] tracking-[1px] text-white md:mb-8 md:pb-0 lg:text-left">
                        <StructuredText
                            data={description}
                            customRules={[
                                renderRule(isParagraph, ({children, key}) => {
                                    if (children[0].length === 0) return <br/>
                                    return (
                                        <p
                                            key={key}
                                            className="text-xl leading-[25.92px] md:font-bold"
                                        >
                                            {children}
                                        </p>
                                    )
                                }),
                            ]}
                        />
                    </div>
                    <div className="flex flex-col items-center lg:inline-flex">
                        <div className="pb-[56px] md:pb-[142px] text-white">
                            <p class="mb-2">PayaCharity, the UK’s largest provider of contactless donation devices, has acquired
                                Toucan as it seeks to strengthen its offering in the Charitable giving sector.</p>
                            <p>The Toucan App has been removed from the App Store and Google Play while we transition
                                over and we hope to have them back up and running as soon as possible.</p>
                            {/*<button
                itemProp="button"
                className="mr-6"
                onClick={() => setMenu(!menu)}
              >
                <img
                  src="https://www.datocms-assets.com/54391/1630472284-download-btn-inline.svg"
                  alt="Download Tucon iOS application from Apple Store"
                />
              </button>
              <button itemProp="button" onClick={() => setMenu(!menu)}>
                <img
                  src="https://www.datocms-assets.com/54391/1630472281-downloadandroid-inline.svg"
                  alt="Download Tucon Android application from Google Play Store"
                />
              </button>*/}
                        </div>
                        <div className="absolute bottom-[-50px]">
                            {showVideoButton && (
                                <VideoThumbnail
                                    className="bg-white/70 shadow-search-bar backdrop-blur-2xl"
                                    onClick={playVideo}
                                />
                            )}
                            {/* ----------- */}
                        </div>
                    </div>
                </div>
                {heroPhones && (
                    <div className="hidden max-w-[535px] md:self-end lg:block">
                        <GatsbyImage
                            image={heroPhones?.gatsbyImageData}
                            alt="Hero Phones"
                            className="mb-0 w-full"
                        />
                    </div>
                )}
            </LayoutContainer>
            {openVideoModal && (
                <YoutubeModal
                    isOpen={openVideoModal}
                    setOpen={setOpenVideoModal}
                    videoId={youtubeVideoId}
                    autoplay
                />
            )}
        </section>
    )
}

export default Hero
